<template>
  <div class="home">
    <img src="@/assets/images/bridges3-logo-text.webp" class="logo-text">
    <img src="@/assets/images/bridges3-logo.webp" class="logo">
    <router-link to="/voorwoord">{{ $t('preface.title') }}</router-link>
    <router-link to="/map">{{ $t('header.map') }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/libs/_mixins.scss";
  @import "@/assets/sass/libs/_vars.scss";
  
  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
  }

  .logo-text {
    width: 80%;
    max-width: 576px;
    max-height: 25vh;
    object-fit: contain;
  }

  .logo {
    width: 75%;
    max-width: 550px;
    max-height: 50vh;
    margin: 2vh 0;
    object-fit: contain;
  }

  a {
    font-family: "DIN-Condensed-Bold", sans-serif;
    font-weight: bolder;
    color: $red;
    font-size: 20px;
    margin: 5px 0;
    text-transform: uppercase;
  }
</style>